import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const QualityPage = () => {
  return (
    <Layout hero={<HeroImage title="Quality" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <div className="mb-5">
            <Link className="back-to-link" to='/community/about-us'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to about us
            </Link>
          </div>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col md={8}>
              <p>At Orrcon Steel we recognise that our success is derived from a flexible approach to market opportunities and our long-term success depends on our ability to meet or exceed the needs and expectations of our customers.  
              We also understand that the quality of our products; services and delivery performance are critical.  
              “We'll see it through” is more than a tag line next to our brand. 
              It is a statement that says a lot about our business and the people who work for Orrcon Steel. 
              It is a part of our culture of being honest, ethical and reliable and it reflects our relationships both internally and externally.</p>
              <p>Our quality objectives are to:</p>
              <ul>
                <li>Perform strategic planning considering risk and opportunities, develop and communicate business objectives and assist the organisation to work toward them in a prioritised and systematic way.</li>
                <li>Ensure that suitable and adequate resources are available to meet strategic plans, operational requirements and continuous improvement of the Quality Management System.</li>
                <li>Monitor and review the requirements of customers and other interested parties and have capable systems and processes in place that achieve their intended results.</li>
                <li>Measure and evaluate our performance and capabilities and undertake decisions based on facts, data and a consideration of risk.</li>
                <li>Continuously improve business efficiency by monitoring, reviewing and developing our systems, processes, products and services.</li>
              </ul>
              <p>To meet these quality objectives Orrcon Steel's Leadership Team are committed to providing direction, and adequate resources to:</p>
              <ul>
                <li>
                  Continually strive to improve the suitability and performance of products and services to our customers.
                </li>
                <li>Build capability and a positive attitude, through employee training, education, support and communication. Enhancing our competitive advantages through a talented and diverse workforce.</li>
                <li>Relate customer expectations, business objectives and product quality requirements.</li>
                <li>Investigate and respond to enquiries, concerns and issues from customers & other interested parties, in a way that meets and exceeds their expectations.</li>
                <li>Comply with applicable laws, regulations and standards.  Where these do not exist and are deemed necessary, we adopt and apply internal standards, which reflect the Company commitment to Our Bond.  </li>
                <li>Maintain, continually develop and improve a Management System based on the requirements of AS/NZS ISO 9001 & AS ISO/IEC 17025.</li>
              </ul>
            </Col>
            <Col md={4}>
              <p>Our steel is manufactured, inspected and tested to comply with one or more of the following standards, including:</p>
              <ul>
                <li>
                Structural - AS/NZS 1163 Cold-formed structural steel hollow sections
                </li>
                <li>
                Low pressure water/gas pipe - AS1074 Steel tubes and tubulars for ordinary service
                </li>
                <li>
                Precision - AS1450 Steel tubes for mechanical purposes
                </li>
                <li>
                ALLGAL® standard - AS4750 Electro-galvanised (zinc) coating on ferrous hollows and open sections.
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default QualityPage

export const Head = () => <title>Quality | Orrcon Steel</title>
